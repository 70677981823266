<template>
  <v-container class="about ps-6" >
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <h1 class="text-h4 d-inline-flex">
      <v-icon color="secondary" size="40" class="pe-2">
        mdi mdi-inbox-full
      </v-icon>
      E-Mail Postfach
    </h1>
    <v-card flat class="rounded-xl pa-2 pa-sm-4 mt-6" >
      <div v-if="loaded">
        <v-row  v-for="msg of inbox" :key="msg.subject+msg.date" class="customerRow ">
          <v-col cols="3" class="overflow-hidden pe-8">
            <span style="white-space: nowrap;" >  {{msg.from[0]}}</span>
          </v-col>
          <v-col cols="" class="overflow-hidden ps-8 pe-8">
            <span style="white-space: nowrap" class="overflow-hidden">{{msg.subject[0]}}</span>
          </v-col>
          <v-col cols="2" class="overflow-hidden ps-8">
            <span style="white-space: nowrap;">{{dateToString(msg.date)}}</span>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col>
            <v-btn @click="loadNextPack()">Mehr laden</v-btn>
          </v-col>
        </v-row>
      </div>
      <Spinner v-else></Spinner>
    </v-card>
   </v-container>
</template>
<script>


import {error, date2StringTime, msgObj} from "@/helper/helper";
import {mapGetters} from "vuex";
const Spinner = () => import("@/components/generalUI/Spinner");
const Message = () => import("@/components/generalUI/Message");

export default {
  components:{
    Spinner,
    Message
  },
  name: 'Inbox',
  data(){
    return {
      loaded:false,
      message: msgObj(),
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('auth/checkLogin', {}).then((res) => {
      this.$store.dispatch('mail/getInbox', {
        uid: res
      }).catch(() => {
        this.message = error('Fehler beim Laden der E-Mails. Bitte versuche es später erneut.')
      }).finally(()=>{
        this.loaded=true
      })
    })
  },
  computed:{
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('mail', {
      inbox: 'inbox',
      mailsLoaded: 'mailsLoaded',
    }),
  },
  methods:{
    dateToString(date){
      return date2StringTime(date)
    },
    loadNextPack(){
      this.$store.dispatch('mail/getNextInboxPack', {
        mailsLoaded: this.mailsLoaded,
      }).then(()=>{
        this.inbox.sort(function(a,b){
         return new Date(b.date) - new Date(a.date);
        });
      }).catch(() => {
        this.message = error('Fehler beim Laden der E-Mails. Bitte versuche es später erneut.')
      })
    }
  }
}
</script>
<style>
.customerRow {
  border-top: dotted 1px #F1E7E2;
  border-bottom: dotted 1px #F1E7E2;
  transition: 0.4s;
}

.customerRow:hover {
  transform: scale(1.01);
  transition: 0.4s;
  background-color: #F3F4F3;
  cursor: pointer;
}

</style>
